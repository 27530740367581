<template>
  <section class="parking-ticket-fighter">
    <h1 class="text-white">Parking Ticket Fighter</h1>
    <div class="description mb-2">
      <p>
        Maximise your chance of overturning a parking ticket. Answer the questions below, and our team will reach-out if we need any further information to create your parking ticket appeal <img :src="`${urlBucket}/dashboard/parkimeter-emoji.png`"  width="18px" style="margin-left: 3px;">
      </p>
    </div>

    <form class="miniform mt-3 mb-3" @submit.prevent>
      <div class="text-center mb-2">
        <img
          :src="`${urlBucket}/dashboard/Parking Ticket Fighter-header-miniform.png`"
          class="img-fluid"
          width="335px"
          alt=""
        />
      </div>

      <div v-if="step == 1" class="mb-2">
        <p class="text-left font-weight-bolder">
          In which city did you received the ticket?
        </p>

        <input
          type="text"
          :placeholder="'In which city did you received the ticket?'"
          class="w-100 p-1"
          v-model="infoAppeal.townTicket"
        />
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="errors.townTicket"
        >
          * This field is required
        </p>
      </div>

      <div v-if="step == 2" class="mb-2">
        <p class="text-left font-weight-bolder">
          Great, what is the ticket number (Notice Number)?
        </p>

        <input
          type="text"
          :placeholder="'Great, what is the ticket number (Notice Number)?'"
          class="w-100 p-1"
          v-model="infoAppeal.ticketNumber"
        />
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="errors.ticketNumber"
        >
          * This field is required
        </p>
      </div>

      <div v-if="step == 3" class="mb-2">
        <p class="text-left font-weight-bolder">Who received the ticket?</p>

        <input
          type="text"
          :placeholder="'Who received the ticket?'"
          class="w-100 p-1"
          v-model="infoAppeal.ticketFor"
        />
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="errors.ticketFor"
        >
          * This field is required
        </p>
      </div>

      <div v-if="step == 4" class="mb-2">
        <p class="text-left font-weight-bolder">
          What is the Reg number of car in question?
        </p>

        <input
          type="text"
          :placeholder="'What is the Reg number of car in question?'"
          class="w-100 p-1"
          v-model="infoAppeal.registrationNumber"
        />
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="errors.registrationNumber"
        >
          * This field is required
        </p>
      </div>

      <div v-if="step == 5" class="mb-2">
        <p class="text-left font-weight-bolder">
          To ensure the best chance of winning your appeal, we need a statement from you. Please
          share a brief explanation of any mitigating circumstances.
        </p>

        <textarea
          type="text"
          class="p-1"
          placeholder=" Please share a brief explanation..."
          v-model="infoAppeal.statement"
          @input="
            (e) => {
              autoGrow(e.target);
            }
          "
        ></textarea>
        <p
          class="mt-1 mb-0 text-left question-error animate__animated animate__shakeX"
          v-if="errors.statement"
        >
          * This field is required
        </p>
      </div>

      <div class="label d-flex align-items-center mt-1 mb-2 px-2 animate__animated animate__pulse" v-if="submitted">
        <img :src="`${urlBucket}/dashboard/check-y.png`" width="30px" alt="" class="mr-1" />
        <p class="ms-1 mb-0">
          Excellent, we'll reach out by email if we need anything else from you
        </p>
      </div>

      <button class="button d-flex justify-content-center" :disabled="loading" @click="next()">
        <p class="mb-0 font-weight-bolder mr-1" style="font-size: 18px;">{{ msgButton }}</p>
        <div class="spinner-border text-light" role="status" v-if="loading"></div>
      </button>
    </form>

    <div class="card-appeal mb-2" v-for="(req, index) in requestsHistory" :key="index">
      <div class="text-center mb-2">
        <img :src="`${urlBucket}/dashboard/parkimeter.png`" class="img-fluid" width="50px" alt="" />
      </div>
      <h1 class="font-weight-bolder mt-2 mb-2" style="color: rgb(255, 232, 89)">
        {{ capitalize(user["firstName"]) }}'s Appeal
      </h1>
      <p>
        <span class="font-weight-bolder desc">Submitted:</span>{{ formatDate(req["requestDate"]) }}
      </p>
      <p class="mb-3">
        <span class="font-weight-bolder desc">Status:</span>{{ req["status"] }}
        <img
          v-if="req['status'] == 'In Progress'"
          :src="`${urlBucket}/dashboard/circle-in-progress.png`"
          class="img-fluid"
          width="20px"
        />
      </p>

      <p><span class="font-weight-bolder desc">Town Ticket:</span>{{ req["townTicket"] }}</p>
      <p><span class="font-weight-bolder desc">Ticket Number:</span>{{ req["ticketNumber"] }}</p>
      <p>
        <span class="font-weight-bolder desc">Registration Number:</span
        >{{ req["registrationNumber"] }}
      </p>
      <p><span class="font-weight-bolder desc">Ticket received by:</span>{{ req["ticketFor"] }}</p>

      <p><span class="font-weight-bolder desc">Statement:</span>{{ req["statement"] }}</p>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";
import Vue from "vue";

export default {
  name: "ParkingTicketFighter",
  components: {},
  data() {
    return {
      loading: false,
      submitted: false,
      step: 1,
      msgButton: "Continue",
      infoAppeal: {
        email: JSON.parse(localStorage.getItem("userData")).email,
        townTicket: null,
        ticketNumber: null,
        registrationNumber: null,
        ticketFor: null,
        statement: null,
      },
      errors: {
        townTicket: false,
        ticketNumber: false,
        registrationNumber: false,
        ticketFor: false,
        statement: false,
      },
      requestsHistory: [],
    };
  },
  mounted() {
    this.requestsHistory = this.lastMove.admin["parkingTicketFighterRequests"];
  },
  methods: {
    capitalize(string) {
      return string.replace(/^\w/, (c) => c.toUpperCase());
    },
    displayErrors() {
      this.errors.townTicket = !this.infoAppeal.townTicket ? true : false;
      this.errors.ticketNumber = !this.infoAppeal.ticketNumber ? true : false;
      this.errors.registrationNumber = !this.infoAppeal.registrationNumber ? true : false;
      this.errors.ticketFor = !this.infoAppeal.ticketFor ? true : false;
      this.errors.statement = !this.infoAppeal.statement ? true : false;
    },
    clearErrors() {
      for (const [key, value] of Object.entries(this.errors)) {
        this.errors[key] = false;
      }
    },
    hasErrors() {
      return document.getElementsByClassName("question-error").length > 0;
    },
    next() {
      this.displayErrors();

      setTimeout(() => {
        // console.log(this.hasErrors());
        if (this.hasErrors()) {
          return;
        }

        if (this.step <= 4) {
          this.step++;
          this.clearErrors();
          return;
        }

        if (this.step == 5) {
          this.loading = true;
          console.log("infoAppeal", this.infoAppeal);

          // return;

          //Submit Data
          axiosCustom
            .post(`/dashboard/parkingTicketFighter`, this.infoAppeal)
            .then((result) => {
              console.log(result.data);
              this.submitted = true;

              //refreshing history
              axiosCustom.get(`api/user/getDetails?email=${this.user.email}`).then(({ data }) => {
                this.requestsHistory = data.moves[data.moves.length - 1].admin["parkingTicketFighterRequests"];
              });
              //refreshing history
            })
            .catch((err) => {})
            .finally(() => {
              this.loading = false;
            });
        }
      }, 100);
    },
    formatDate: (d) => {
      let date = new Date(d);
      let options = { day: "numeric", month: "long" };
      let dateString = date?.toLocaleDateString("en-GB", options);
      let day = date?.getDate();
      let suffix = "th";
      if (day === 1 || day === 21 || day === 31) {
        suffix = "st";
      } else if (day === 2 || day === 22) {
        suffix = "nd";
      } else if (day === 3 || day === 23) {
        suffix = "rd";
      }
      return dateString?.replace(/\d+/, day + suffix);
    },
    autoGrow(element) {
      let limit = 600; //height limit
      element.style.height = "";
      element.style.height = Math.min(element.scrollHeight, limit) + "px";
    },
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "currentMove", "logoPartner", "lastMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {
    step(val) {
      // console.log('step',val)
      if (val == 5) {
        this.msgButton = "Submit";
      }
    },
  },
};
</script>

<style lang="scss">
.parking-ticket-fighter {

  margin-left: 12em;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  h1 {
    font-weight: bold;
  }
  
  p {
    color: white;
    font-size: 16px;
  }

  .miniform,
  .description,
  .card-appeal {
    max-width: 550px;
    text-align: justify;
  }

  .description p{
    line-height: 1.7;
  }

  .miniform,
  .card-appeal {
    border: 5px solid #4afff4;
    border-radius: 30px;
    background-color: #212534;
    padding: 27px 40px;
    padding-top: 15px;
  }

  .card-appeal {
    .desc {
      margin-right: 4px;
    }

    p{
      word-wrap: break-word;
    }
  }

  .miniform {
    .question-error {
      font-size: 14px;
      color: #c64a45;
    }

    input[type="text"] {
      outline: none;
      border-radius: 0.375rem;
      border: none;
      color: black;
      font-size: 16px;
    }

    textarea {
      height: 90px;
      width: 100%;
      overflow: hidden;
      /* resize: none;*/
      outline: none;
      border-radius: 0.375rem;
      border: none;
      color: black;
      font-size: 16px;
    }

    .selection {
      .col {
        max-width: 125px;
      }
      .squares {
        text-align: center;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        color: #000;
        box-shadow: 0 1px 7px rgba(11, 45, 150, 0.2);
        transition: all 0.1s ease-in-out;

        img {
          width: 70px;
          height: 67px;
        }

        p {
          color: #000;
        }
      }

      .selected {
        background-color: #00b9ee;
        p {
          color: white;
        }
      }
    }

    p {
      color: white;
    }

    .label {
      background-color: rgba(57, 61, 74, 0.62);
      border-radius: 10px;
      padding: 6px;
    }

    button {
      all: unset;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 15px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 13px;
      font-size: 16px;
    }

    .button {
      background-color: #ec0677;
      box-shadow: 0 5px 0 #7d0741;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
</style>
